<template>
  <div class="reg">
    <div class="reg-header">
      <topNav></topNav>
    </div>
    <div class="reg-main">
      <!-- <div class="m-steps">
        <Steps :current="curIndex">
          <Step title="Email verification"></Step>
          <Step title="Add your Profile"></Step>
          <Step title="Registration completed"></Step>
        </Steps>
      </div> -->

      <div class="admin-add">
        <div class="admin-add-content">
          <div style="text-align: center">You need to associate an e-mail</div>
          <Form class="F" ref="formInline" :model="formInline" :rules="ruleInline" @keyup.enter.native="handleSubmit('formInline')">
            <FormItem class="F-1" prop="passWord">
              <span>New Password:</span>
              <Input class="I" type="text" v-model="formInline.password" placeholder="" />
            </FormItem>
            <FormItem class="F-1" prop="ConPassword">
              <span>Confirm Password:</span>
              <Input class="I" type="text" v-model="formInline.ConPassword" placeholder="" />
            </FormItem>
            <Button type="primary" @click="handleSubmit('formInline')" size="large" class="btn">Next</Button>
          </Form>
        </div>
      </div>

      <!-- <iModal v-model="showModel" :mask-closable="false" @on-cancel="cancel" :styles="{ top: '200px' }" :footer-hide="true">
        <div class="model">
          <svg class="icon-svg icon-big" aria-hidden="true">
            <use xlink:href="#icon-j"></use>
          </svg>
          <p>
            {{ this.formInline.email }}
            <br />
            The email is already in use
            <br />
            Please confirm if it belongs to you
          </p>
          <div class="user-info">
            <img :src="userMsg.avatarUrl" v-if="userMsg.avatarUrl" />
            <img src="@/assets/img/empty.png" v-else />
            <span>{{ userMsg.nickName || userMsg.name }}</span>
          </div>
          <div class="bot-btn">
            <Button type="primary" @click="linkTo('no')">No. Keep registering</Button>
            <Button type="primary" @click="linkTo('yes')">Yes. sign in now</Button>
          </div>
        </div>
      </iModal> -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validator } from "@/other/validator";
import topNav from "./components/top";

export default {
  name: "reg",

  data() {
    const ConPassword = (rule, value, callback) => {
      if (!value) {
        callback("please enter Confirm password");
        return;
      } else if (value != this.formInline.password) {
        callback("The password is inconsistent");
        return;
      } else {
        callback();
      }
    };
    return {
      curIndex: 0,
      formInline: {
        password: "",
        ConPassword: "",
      },
      ruleInline: {
        passWord: [
          {
            type: "string",
            max: 20,
            message: "not exceed 20",
            trigger: "blur",
          },
          {
            type: "string",
            min: 6,
            message: " no fewer than 6",
            trigger: "blur",
          },
        ],
        ConPassword: [{ validator: ConPassword, trigger: "blur" }],
      },
      showCode: true,
      count: "",
      codeKey: "",
      userMsg: "",
      showModel: false,
    };
  },
  components: {
    topNav,
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      forgetEmail: "getForgetEmail",
    }),
  },
  methods: {
    ...mapActions({
      getImgCode: "loginModule/getImgCode",
      validateImgCode: "loginModule/validateImgCode",
      getMsgCode: "getMsgCode",
      validateCode: "validateCode",
      graphqlPost: "graphqlPostByZXS",
      linkToLogin: "linkTo/linkToLogin",
      sendEamilCode: "en/sendEamilCode",
      linkToPassword: "linkTo/linkToPassword",
      editpassword: "en/editpassword",
    }),
    cancel() {
      this.$refs["formInline"].resetFields();
      this.codeKey = "";
      this.formInline.jobTitle = "";
      this.formInline.name = "";
    },
    async getPhoneCode() {
      this.$refs.formInline.validateField("email", (val) => {
        if (val !== "") return;
        this.formInline.code = "";
        this.$refs.captcha_input2.focus();
        this.downTime();
      });
    },

    validForm(name) {
      // 验证表单
      return new Promise((resolve, reject) => {
        this.$refs[name].validate((valid) => {
          resolve(valid);
        });
      });
    },
    async handleSubmit(name) {
      let valid = await this.validForm(name);
      if (!valid) return;
      this.editPassword();
    },
    async editPassword() {
      let opt = {
        email: this.forgetEmail,
        password: this.formInline.ConPassword,
      };
      let data = await this.editpassword(opt);
      if (data.result) {
        this.$Message.success("Success");
        setTimeout(() => {
          this.linkToLogin();
        }, 2000);
      } else {
        this.$Message.error(data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.reg {
  &-header {
    box-shadow: 0px 2px 3px 0px rgba(101, 101, 101, 0.1);
  }
  &-main {
    width: 1200px;
    margin: 50px auto;
    ::v-deep .m-steps {
      width: 710px;
      margin: 0 auto;
      .ivu-steps-item {
        .ivu-steps-head {
          background: transparent;
        }
      }
    }
  }
  .F {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 380px;
    position: relative;
    margin: 0 auto;
    &-1 {
      margin-top: 34px;
      margin-bottom: 0;
      &.phone_code {
        ::v-deep {
          .ivu-form-item-content {
            display: flex;
            .ivu-btn {
              margin-left: 10px;
            }
          }
        }
      }
    }
    .F-2 {
      width: 100%;
      height: 15px;
      line-height: 15px;
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
      .F-2-text {
        width: 48px;
        height: 13px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
      }
    }
    .wx-btn {
      margin-top: 20px;
    }
    .code {
      width: 70px;
      height: 26px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      cursor: pointer;
    }
    .btn {
      margin-top: 34px;
    }
  }
}
.model {
  width: 340px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .icon-big {
    font-size: 100px;
    text-align: center;
    margin: 0 auto;
  }
  p {
    font-size: 16px;
    text-align: center;
    padding: 18px 0 34px;
    line-height: 2;
  }
  .user-info {
    width: 100%;
    height: 100px;
    background-color: #f7f7f7;
    border-radius: 10px;
    padding: 18px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    img {
      width: 64px;
      height: 64px;
      display: block;
      margin-right: 14px;
      border-radius: 50%;
    }
    span {
      font-size: 16px;
    }
  }
  .bot-btn {
    display: flex;
    justify-content: space-between;
  }
}
</style>
